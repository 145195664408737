* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.boards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.phase-message {
  margin: 0 auto;
  max-width: 700px;
}

.has-white-space-nowrap {
  white-space: nowrap;
}

.winner-container {
  position: relative;
}

.winner-icon {
  position: absolute;
  left: 0em;
  height: 100%;
  font-size: 4em;
}

@media screen and (max-width: 800px) {
  .boards {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
