.board {
  flex: 1;
}

p {
  margin: 0;
  padding: 0;
}

.orientation {
  width: 2.5em;
  text-align: right;
}

@media screen and (max-width: 800px) {
  .board {
    flex: initial;
  }
}
